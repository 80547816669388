<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="families"
      :search="search"
      :loading="!families"
      loading-text="Loading... Please wait"
      sort-by="name,code,suff"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-t="'brand'"></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" v-t="'add'">
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="validItem">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.code"
                          :label="$t('code')"
                          :counter="12"
                          :rules="codeRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.description"
                          :label="$t('description')"
                          required
                          :counter="128"
                          :rules="descriptionRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.suffix"
                          :label="$t('suffix')"
                          :counter="2"
                          :rules="suffixRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close" v-t="'cancel'"></v-btn>
                <v-btn color="blue darken-1" text @click="save" v-t="'save'"></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">{{ $t('deleteText', {param:$t('family')}) }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete" v-t="'cancel'"></v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm" v-t="'confirm'"></v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn dense icon raised elevation="2" color="secondary" @click="editItem(item)" class="mx-2"><v-icon small> mdi-pencil </v-icon></v-btn>
        <v-btn dense icon raised elevation="2" color="error" @click="deleteItem(item)"><v-icon small> mdi-delete </v-icon></v-btn>
      </template>
      <template v-slot:no-data>
        <NoDataImg/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sync, call } from "vuex-pathify";
import NoDataImg from '../../components/NoDataImg.vue';

export default {
  components: {
      NoDataImg
    },
  data: () => ({
    // parametro di validazione del form
    validItem: false,
    dialog: false,
    dialogDelete: false,
    search: "",
    editedIndex: -1,
    editedItem: {
      code: "",
      description: "",
      suffix: "",
    },
    defaultItem: {
      code: "",
      description: "",
      suffix: "",
    },
  }),

  computed: {
    codeRules() {
      return [
        (v) => !!v || this.$t('required', {param: this.$t('code')}),
        (v) => (v || "").indexOf(" ") < 0 || this.$t('whitespaces'),
        (v) =>
          v.length <= 12 || this.$t('lessThan', {param: this.$t('code'), qty: v.length}),
      ]
    },
    descriptionRules() {
      return [
        (v) =>
          v.length <= 128 || this.$t('lessThan', {param: this.$t('description'), qty: v.length}),
      ]
    },
    suffixRules() {
      return [
        (v) => !!v ||  this.$t('required', {param: this.$t('suffix')}),
        (v) => (v || "").indexOf(" ") < 0 || this.$t('whitespaces'),
        (v) =>
          v.length <= 2 || this.$t('lessThan', {param: this.$t('suffix'), qty: v.length}),
      ]
    },
    headers() {
      return [{
          text: this.$t('code'),
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: this.$t('description'), value: "description" },
        { text: this.$t('suffix'), value: "suffix" },
        { text: this.$t('actions'), value: "actions", sortable: false, align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('newParam', {param:this.$t('item')}) : this.$t('editParam', {param:this.$t('item')});
    },
    families: sync("families/families"),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.initFamilyCollection();
    },

    editItem(item) {
      this.editedIndex = this.families.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.families.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.familyDelete(this.editedItem.id);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.familyUpdate(this.editedItem);
      } else {
        this.familyPost(this.editedItem);
      }
      this.close();
    },

    ...call("families/*"),
  },
};
</script>